@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: "Blinker", sans-serif;
  font-weight: 400;
  background-color: #000;
  color: #3e3e3e;
  overflow-x: hidden;
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Blinker", sans-serif;
  font-weight: bold;
}
a {
  color: #d4a0ff;
  text-decoration: none;
}
a:hover {
  color: #d4a0ff;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #090808;
}
img.brand_logo {
  width: 120px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0d0d0d;
  margin-top: 0 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* ul.navbar_left {
  margin-left: 26%;
} */
.bal{
  color: #3e3e3e;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
}
.bgcolor_nav{
  background: #0d0d0d;
  z-index: 999;
  position: relative;
}
.main_navbar a.nav-link {
  color: #3e3e3e;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link.active,
.main_navbar a.nav-link:hover {
  color: #d4a0ff;
  text-decoration: none;
}
.main_wrapper {
  min-height: 100vh;
}
.innerpages_wrapper {
  min-height: 100vh;
  margin: 50px 0;
  position: relative;
}
.main_navbar {
  padding: 12px 20px;
  z-index: 999;
  justify-content: space-between;
}
.inner_title_wrapper {
  margin: 35px 0 0;
}
.inner_title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_wrapper {
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(4.5px);
  padding: 40px;
  margin-bottom: 30px;
}
.dash_box {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  border: 1px solid rgb(223 223 223 / 50%);
  padding: 30px;
  margin-bottom: 24px;
}
.dash_box_left_flex {
  display: flex;
  align-items: center;
}
.dash_box_left_flex > div {
  width: 100%;
}
.dash_box_left h3 {
  color: #212529;
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_left h4 {
  color: #626262;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_flex {
  display: flex;
  align-items: center;
}
.dash_box_right_flex > div {
  width: 100%;
}
.dash_box_right h3 {
  color: #212529;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_right h4 {
  color: #626262;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.tradingview-widget-container {
  height: 100%;
}
.tradingview-widget-container > div {
  height: 100%;
}
.time_card {
  min-width: 60px;
  width: 20%;
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.count_value {
  font-size: 32px;
  line-height: 38px;
}
.count_indicator {
  font-size: 12px;
  font-weight: 600;
}
.counter {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-blend-mode: overlay;
}
.px3 {
  padding-right: 3rem !important;
}
.affiliate_inpgrp {
  position: relative;
  margin: 14px 0;
}
.affiliate_inpgrp input {
  border-radius: 20px;
  background: #f5f5f7;
  border: 1px solid #f5f5f7;
  padding: 8px 12px;
  color: #212529;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.affiliate_list li a {
  background: #b4b4b4;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.affiliate_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.affiliate_list li a:hover {
  background: #d4a0ff;
}
.affiliate_list li a svg {
  fill: #fff;
}
.copy_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.txt_green {
  color: #d4a0ff !important;
}
.txt_red {
  color: #ff6363 !important;
}
.dash_box_right_top {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
.dash_box_right_top img {
  border-radius: 100px;
  width: 30px;
}
.dash_box_right_top h4 {
  font-size: 16px;
  font-weight: 600;
}
.flex-dashbox-top b span{
  font-size: 16px;
}
.apy_tooltip {
  width: 250px !important;
}
.custom_tooltip {
  background: #fff !important;
  color: #626262 !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 8px rgba(40, 40, 40, .08);
  opacity: 1 !important;
  z-index: 99;
}
.dash_box_right_bottom > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dash_box_right_bottom .img_box{
  height: 170px;
  justify-content: center;
}
.dash_box_right_bottom .img_box img{
  max-height: 150px;
}
.dash_box_right_bottom > div label:nth-child(2) {
  font-weight: 500;
}
.dash_box_right_bottom h2 {
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.dash_box_right_bottom h2 span {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.staking_method_btngrp {
  flex-wrap: wrap;
}
.primary_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: uppercase;
  color: #030303;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #d4a0ff;
  cursor: pointer;
  outline: none;
  font-family: "Blinker";
  font-weight: 600;
  transition: .5s background-color;
}
.primary_btn:hover { 
  border: 1px solid #d4a0ff !important;
  background-color: transparent; 
  transition: .5s background-color;
  color: #fff;
}
.dark_btn{
  border: 1px solid #d4a0ff !important;
  background-color: transparent;  
  color: #fff;
}
.dark_btn:hover{
border: 1px solid transparent; 
  background-color: #d4a0ff;
  color: #030303;
}
.button_grp {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom_progress {
  position: relative;
}
.progress_value {
  position: absolute;
  top: -7px;
  right: 0;
  color: #d4a0ff;
  font-size: 14px;
  font-weight: 700;
}
.custom_progress .progress {
  border-radius: 10px;
  background: #ececef;
  height: 10px;
}
.custom_progress .progress,
.progress_note {
  width: 95%;
}
.progress_note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.progress_note label {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_note label:nth-child(2) {
  color: #626262;
  font-weight: 700;
}
.custom_progress .progress-bar {
  background: #d4a0ff;
  border-radius: 10px;
}
.dash_box_right h5 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dash_box_right_large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dash_box_right_large_single {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.dash_box_right_large_single h3 {
  color: #212529;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_large_single::after {
  content: "";
  background: #e0e0e0;
  width: 1px;
  height: 63px;
  position: absolute;
  right: -42px;
  top: 0;
}
.dash_box_right_large_single:last-child::after {
  display: none;
}
.dash_box_right_large_single h5 {
  margin-bottom: 18px;
}
.dash_box.dash_box_right.dash_box_right_large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.dash_box.dash_box_right {
  padding-top: 36px;
  padding-bottom: 36px;
}
.inner_subtitle_wrap h3 {
  color: #d4a0ff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.inner_subtitle_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.primary_datatable .rdt_Table,
.primary_datatable .rdt_Table > div {
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow,
.primary_datatable .rdt_TableHeadRow {
  border-bottom: 0;
  background: transparent;
}
.primary_datatable .rdt_Pagination {
  border-top: 0;
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(185 238 249 / 50%);
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-transform: capitalize;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}

.primary_datatable_chg .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-last-child(1) >div{
  overflow: visible;
  white-space: unset;
}
.footer {
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4.5px);
  padding: 30px 0 15px;
  position: relative;
  z-index: 1;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_bottom p {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer_panel_bottom p a {
  color: #1e1e1e;
  font-weight: 500;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li::after {
  content: "";
  background: #898989;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 6px;
  right: -15px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
p.footer_abt_content {
  color: #0f0f0f;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; 
}
.footer_abt_content a{
  font-weight: 400;
font-size: 18px;
line-height: 167%;
letter-spacing: -0.03em;
color: #bcbcbc;
padding-right: 10px;
}
.footer_abt_content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 0;
  gap: 10px;
}
.footer_panel_top h3 {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.42px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer_panel_top .navbar-brand img {
  width: 122px;
  margin-bottom: 12px;
}
.footer_quick_links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_quick_links li a {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.42px;
  text-transform: capitalize;
}
.footer_social_links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.footer_social_links li a {
  background: #0C0C0C;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #d4a0ff;
}
.footer_social_links li a:hover path{
  fill: #030303;
}
.footer_social_links li a svg {
  fill: #fff;
}
.footer_panel_top {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.auth_btn_grp {
  display: flex;
  gap: 15px;
}
.offcanvas-header .btn-close {
  box-shadow: none;
}
.toolbar_bottom {
  display: none;
}
.page_header {
  position: relative;
  z-index: 1;
}
.page_header::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banbg.png") no-repeat center;
  width: 100%;
  height: 584px;
  top: -140px;
  z-index: -1;
  left: 20px;
}
.page_header_inner {
  padding: 89px 57px 89px 56px;
  position: relative;
  margin-top: 50px;
}
.page_header .page_header_inner::before{
  content: "";
  position: absolute;
  background: url("./assets/images/ban_left.png") no-repeat left top;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.page_header .page_header_inner::after{
  content: "";
  position: absolute;
  background: url("./assets/images/ban_right.png") no-repeat right top;
  width: 100%;
  height: 100%;
  right:  0;
  top: 0;
  z-index: -1;
}
.primary_btn_with_bg {
  border: 1px solid #d4a0ff !important;
  background-color: transparent;
  color: #fff;
}
.primary_btn_with_bg:hover {
  background-color: #d4a0ff;
  color: #030303;
}
.page_header .sub_head{
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  color: #bcbcbc;
}
.page_header .blw_ctn{
  width: 42%;
  margin-top: 100px;
}
.page_header h1 {
  color: #fff;
  font-size: 100px;
  font-weight: 600;
  line-height:95%;
  text-align: center;
  margin-bottom: 30px;
}
.page_header h1 span {
  color: #000;
  background-image: linear-gradient(45deg, #d4a0ff, #d4a0ff);
  transform: rotate(1deg);
  border-radius: 15px;
  line-height: 64px;
  display: inline-block;
  background-position: 0 -360px;
  padding-bottom: 19px;
}
.page_header p {
  color: #bcbcbc;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 25px;
  z-index: 10;
  position: relative;
}
.main_wrapper {
  min-height: 100vh;
  z-index: 1;
  position: relative;
}
.section {
  padding: 50px 0;
}
.countdown_single {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 230, 201, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  width: 260px;
}
.countdown_single:nth-child(2) {
  background: rgba(231, 223, 255, 0.5);
}
.countdown_single:nth-child(3) {
  background: rgba(227, 250, 255, 0.75);
}
.countdown_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.countdown_single h3 {
  color: #ff8a00;
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 0;
}
.countdown_single p {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.countdown_single:nth-child(2) h3 {
  color: #8c64ff;
}
.countdown_single:nth-child(3) h3 {
  color: #d4a0ff;
}
.countdown_section {
  padding: 0;
  z-index: 10;
  position: relative;
}
.main_title {
  color: #1e1e1e;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
}
.main_title span {
  color: #d4a0ff;
}
.main_para {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}
.main_para b {
  color: #1e1e1e;
  font-weight: 500;
}
.features_panel {
  border-radius: 60px;
  background: #dafff2;
  padding: 50px 80px;
}
.features_panel h3 {
  color: #d4a0ff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
}
.features_panel p.main_para {
  max-width: 85%;
}
.zindex{
  z-index: 10;
}
.staking_works {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 30px;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  z-index: 20;
  border: 1px solid transparent;
}
.staking_works p{
  margin-bottom: 0;
  font-size: 20px;
}
.staking_works h2 {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}
.staking_calc {
  border-radius: 40px;
  background: #fefefe;
  padding: 50px;
  position: relative;
  overflow: hidden;
}
.dash_box .staking_calc::before{
  display: none;
}
.dash_box .staking_calc::after{
  display: none;
}
.staking_calc::before {
  content: "";
  position: absolute;
  background: url("./assets/images/calculatorbg_left.png") no-repeat center;
  width: 164px;
  height: 145px;
  top: 0;
  left: 0;
}
.staking_calc::after {
  content: "";
  position: absolute;
  background: url("./assets/images/calculatorbg_right.png") no-repeat center;
  width: 319px;
  height: 314px;
  bottom: 0;
  right:  0;
  z-index: -1;
}
.arrows {
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  /* filter: invert(1); */
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  margin: auto;
  padding: 2px;
  position: absolute;
  right: 15px;
  top: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.faq_accordian {
  background: transparent;
}
.faq_accordian button {
  box-shadow: none !important;
}
.faq_accordian .accordion-item {
  border: none;
  background: #030303;
  margin-bottom: 15px;
  border-radius: 15px;
}
.faq_accordian .accordion-item:last-child {
  border-bottom: 0;
}
.faq_accordian .accordion-item button {
  background: transparent;
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 25px 40px;
}
.faq_accordian .accordion-button::after {
  background: url("../src/assets/images/plus.png") no-repeat;
  width: 26px;
  height: 22px;
  background-size: contain;
}
.faq_accordian .accordion-button:not(.collapsed)::after {
  background: linear-gradient(180deg, #030303 51%, white 2%, #030303 59%);
}
.faq_accordian .accordion-body {
  padding: 10px 40px 15px;
}
.faq_accordian .accordion-body .main_para {
  margin-bottom: 0;
  max-width: 90%;
}
.image_dropdown {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background: #f3f3f3 url("../src/assets/images/select_dropdown.png") no-repeat
    center right 15px !important;
  border: 1px solid #f3f3f3 !important;
  height: 60px;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
}
.dropdown-toggle.image_dropdown::after {
  display: none;
}
.image_dropdown img,
.image_dropdown_wrapper .dropdown-menu img {
  margin-right: 10px;
}
.image_dropdown_wrapper .dropdown-menu {
  width: 100%;
  padding: 0;
  height: 330px;
  overflow-y: auto;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item.active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:focus,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:hover {
  background: rgb(0 0 0 / 20%);
  color: #fff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.staking_calc input {
  height: 60px;
  border-radius: 20px;
  background: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
  box-shadow: none !important;
}
.staking_calc .input-group .primary_asset_inp {
  height: 60px;
  font-size: 30px;
  border-radius: 20px;
  border: 0 !important;
  background: #d4a0ff !important;
}
.MuiSlider-root {
  color: #d4a0ff !important;
}
.staking_calc label {
  color: #3e3e3e;
  font-size: 16px;
  margin-bottom: 10px;
}
.staking_calc [class*="col-"] {
  margin-bottom: 30px;
}
.inactive {
  display: none;
}
.staking_method_btngrp {
  display: flex;
  gap: 10px;
}
.staking_method_btngrp button {
  color: #fff;
  font-family: "Gilroy";
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #aa6dde;
  background: transparent;
  padding: 0 15px;
  white-space: nowrap;
  height: 42px;
}
.staking_method_btngrp button.active {
  background: #aa6dde;
  border: 1px solid #aa6dde;
  color: #fff;
}
.staking_result_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 80px;
  border-radius: 20px;
  border: 0;
  background: rgba(255, 255, 255, 0.15);
}
.staking_result_div p {
  color: #3e3e3e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.staking_result_div h3 {
  color: #1e1e1e;
  font-size: 40px;
  line-height: normal;
  font-weight: bold;
}
.staking_result_div span {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  position: relative;
}
.auth_wrapper .dash_box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  position: relative;
  padding: 50px 80px;
  z-index: 1;
}
.innerpages_wrapper::before {
  content: "";
  /* background: url("../src/assets/images/banner_bg.png") no-repeat; */
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -120px;
  z-index: -1;
}
.auth_form input.form-control {
  background: #ecfcff !important;
  border: 1px solid rgb(0 205 249 / 30%) !important;
  backdrop-filter: blur(2.5px);
  border-radius: 50px;
  height: 56px;
  padding-left: 25px;
  box-shadow: none !important;
  color: #626262;
}
.auth_form input.form-control::placeholder {
  color: #626262;
}
.auth_form .form-check-input:checked {
  background-color: #d4a0ff;
  border-color: #d4a0ff;
  box-shadow: none !important;
}
.auth_form .form-check-input:focus {
  box-shadow: none !important;
}
.auth_form .primary_btn_with_bg {
  padding: 12px 18px;
  font-size: 18px;
}
ul.nav_after_login {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav_after_login li {
  position: relative;
}
.nav_after_login li a.nav-link {
  color: #1e1e1e !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login .dropdown svg {
  margin-right: 10px;
}
.notify_active {
  background: #d22102;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav_after_login li a.dropdown-item {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login li a.dropdown-item:focus,
.nav_after_login li a.dropdown-item:hover {
  color: #fff;
  background-color: #d4a0ff;
}
.level_top_row .dash_box_right {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.level_top_row .dash_box_left {
  height: 100%;
  padding: 10px 30px;
}
.level_top_row [class*="col-"] {
  margin-bottom: 24px;
}
.level_head,
.level_body_row {
  display: flex;
}
.level_head > div:nth-child(1),
.level_body_row > div:nth-child(1) {
  width: 25%;
  margin-right: 80px;
}
.level_head > div:nth-child(2),
.level_body_row > div:nth-child(2) {
  width: 40%;
}
.level_head > div:nth-child(3),
.level_body_row > div:nth-child(3) {
  width: 20%;
}
.level_head > div {
  color: #d4a0ff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.level_body_row > div {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.level_body_row {
  background: #f0f0f0;
  height: 56px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 30px;
}
.level_ribbon {
  position: relative;
  background: url("../src/assets/images/level_default.png") left center
    no-repeat;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d4a0ff;
}
.level_ribbon.active {
  background: url("../src/assets/images/level_active.png") left center no-repeat;
  color: #fff;
}
.level_table {
  margin-top: 50px;
}
.level_head {
  padding-bottom: 30px;
}
.asset_dashbox_flex {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.asset_dashbox_flex_line {
  position: relative;
}
.asset_dashbox_flex_left,
.asset_dashbox_flex_right {
  width: 50%;
}
.asset_dashbox_flex_line::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: #e0e0e0;
  position: absolute;
  right: 50%;
}
.asset_deposit_innertitle {
  color: #d4a0ff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.primary_warning_alert {
  border-radius: 29px;
  background: #f5f5f7;
  border-color: #f5f5f7;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.primary_warning_alert svg {
  min-width: 26px;
  height: 26px;
}
.primary_warning_alert h3 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.primary_warning_alert p {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.deposit_notes_list {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 15px;
}
.deposit_notes_list li {
  margin-bottom: 15px;
}
.asset_deposit_grp {
  position: relative;
}
.asset_deposit_grp input {
  padding-right: 40px;
}
.primary_asset_inp {
  height: 45px;
  border-radius: 30px;
  background: #ecfcff !important;
  border-color: rgb(0 205 249 / 30%) !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_copy_icon {
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
}
.deposit_asset_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit_asset_details > div label {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
}
.deposit_asset_details > div label:nth-child(2) {
  color: #212529;
  font-weight: 500;
}
.inner_title_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assets_form label {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: block;
}
.withdrawal_type {
  color: #d4a0ff;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 18px;
}
.deposit_asset_details > div.font_bold {
  margin-top: 30px;
}
.deposit_asset_details > div.font_bold label {
  color: #0b0e11;
  font-weight: 600;
}
label.max_label {
  color: #d4a0ff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 8px;
}
.transfer_modal .modal-dialog {
  max-width: 700px;
}
.primary_modal .modal-header h1 {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  box-shadow: none !important;
}
.asset_modal_btn_grp {
  display: flex;
  gap: 24px;
}
.primary_asset_select {
  height: 45px;
  border-radius: 30px;  
  border-color: rgb(0 205 249 / 30%) !important;
  background: #ecfcff url("../src/assets/images/select_dropdown.png") no-repeat;
  background-position: right 0.75rem center;
  background-size: 14px 9px !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_transfer_flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.trade_user_detail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.trade_user_detail h3 {
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.trade_user_detail img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.copy_trade_row .dash_box {
  height: 100%;
}
.copy_trade_row [class*="col-"] {
  margin-bottom: 24px;
}
.cusform_form_switch .form-check-input {
  box-shadow: none !important;
  border-color: #d7d7d7;
}
.cusform_form_switch .form-check-input:checked {
  background-color: #d4a0ff;
  border-color: #d4a0ff;
}
.primary_tab {
  display: flex;
  gap: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #efefef;
}
.primary_tab li button.nav-link {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  color: #afafaf;
  background: transparent;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}
.primary_tab li button.nav-link.active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  color: #0b0e11;
}
.notification_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #0c0c0c;
  padding: 30px 0;
  cursor: pointer;
}
.notification_panel_row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.unread_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: #fd2626;
  position: absolute;
}
.read_dot {
  background: #fff;
}
.notification_row_content {
  margin-left: 15px;
}
.notification_row_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification_panel_row p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--grey-07);
}
.notification_panel_row_empty > div {
  width: 100%;
  align-items: center;
}
.notification_panel_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}
.no_orders {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  width: 100% !important;
}
.user_info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info img {
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.user_info_content h3 {
  color: #0b0e11;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.user_info_content h5 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info_content h5 span {
  color: #626262;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.user_info_content h6 {
  color: #999999;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info_content h6 span {
  cursor: pointer;
}
.center_line {
  margin: 0 5px;
  font-size: 10px;
}
.custom_file_upload {
  position: relative;
  overflow: hidden;
  background: #d4a0ff !important;
  padding: 8px 30px;
  color: #030303 !important;
}
.custom_file_upload input[type="file"] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.alertCard h3 {
  font-weight: 700;
}
.refer-icon {
  height: 16px;
}
.custom_nav_tabs {
  gap: 10px;
}
.flex-dashbox-top {
  justify-content: space-between;
}
.crypto-img-name {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.custom_nav_tabs button.nav-link {
  color: #d4a0ff;
  font-family: "Gilroy";
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid #d4a0ff;
  background: transparent;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 150px;
}
.custom_nav_tabs button.nav-link.active {
  background: #d4a0ff;
  color: #030303;
}
.staking_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search_grp {
  position: relative;
}
.search_grp input {
  padding: 12px 20px;
  box-shadow: none !important;
  border-radius: 20px;
  background: #ecfcff !important;
  border-color: rgb(0 205 249 / 30%) !important;
  width: 300px;
}
.search_icon {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 18px;
}
.input-group .primary_asset_select {
  flex: 0 1 auto;
  width: 140px;
}
.alert-success {
  color: #1E1E1E;
  background: rgba(0, 184, 129, 0.3);
}
.wallet_table {
  background: transparent;
}
.wallet_table th {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background: transparent;
  border: none;
  padding: 10px 30px;
}
.wallet_table td {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
  background: transparent;
  padding: 10px 30px;
}
.table_asset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table_asset img {
  width: 36px;
}
.table_asset span {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.wallet_table td:last-child {
  width: 300px;
}
.wallet_table tbody tr:nth-child(odd) {
  background: rgb(185 238 249 / 50%);
}
.wallet_bal {
  display: flex;
  align-items: center;
  gap: 10px;
}
.wallet_bal h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.wallet_page_right_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.wallet_page_right_flex .form-check-input {
  box-shadow: none !important;
}
.wallet_page_right_flex .form-check-input:checked {
  background-color: #d4a0ff !important;
  border-color: #d4a0ff !important;  
}
.dash_wrapper.dash_wrapper_flex {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  position: relative;
}
.dash_wrapper_flex_left, 
.dash_wrapper_flex_right {
  width: 50%;  
}
.dash_wrapper_flex::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: rgba(0, 184, 129, 0.30);
  position: absolute;
  right: 50%;
}
textarea.form-control.primary_asset_inp {
  height: auto;
  resize: none;
}
.uploaded_files_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(0 0 0 / 15%);
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #1e1e1e !important;
}
.uploaded_files_row > div span {
  font-size: 12px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}
.dash_wrapper_flex_left .primary_btn {
  padding: 15px 20px;
}
.dash_wrapper_flex_right .search_grp input {
  width: 100%;
}
.dash_wrapper_flex .inner_subtitle_wrap_flex {
  margin-bottom: 15px;
}
.support_ticket_accordian .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.support_ticket_accordian .accordion-header .accordion-button > div {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}
.support_ticket_accordian .accordion-button::after {
  position: absolute;
  margin-left: unset;
  right: 0;
}
.ticket_msg_card_user {
  width: 85%;
}
.ticket_msg_card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(160, 160, 160, 0.35);
  padding: 30px;
  margin-bottom: 24px;
}
.ticket_msg_card_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.ticket_msg_card_admin {
  width: 85%;
  margin-left: auto;
}
.ticket_msg_card_header h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_header p {
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_desc p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.ticket_msg_card i {
  font-size: 24px;
}
.chat_reply {
  position: relative;
}
.chat_reply .primary_asset_inp {
  padding-right: 50px;
}
.chat_reply i {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  color: #d4a0ff;
  font-size: 20px;
}
.faq_accordian .accordion-item button.primary_btn {
  width: auto;
  padding: 10px 30px;
}
.faq_accordian .accordion-item .asset_modal_btn_grp {
  justify-content: flex-end;
}
.ticket_msg_card_wrapper {
  height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.stake_modal .staking_method_btngrp button {
  height: auto;
  padding: 5px 12px;
}
.deposit_asset_details > div.modal_staking_period {
  flex-direction: column;
  align-items: flex-start;
}

.phone_num .form-control {
  width: 100% !important;
  padding-left: 40px !important;
  background-color: transparent !important;
  border: 0;
}
.phone_num .react-tel-input .selected-flag:hover, 
.phone_num .react-tel-input .selected-flag:focus{
  background-color: transparent;
}
.phone_num .react-tel-input .flag-dropdown{
  background-color: transparent;
  border: 0;
}
.phone_num .react-tel-input .flag-dropdown.open{
  background: transparent;
}
.phone_num .react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent;
}
.ticker_panel {
  border-radius: 15px;
  background: #ecfcff !important;
  border: 1px solid rgb(0 205 249 / 30%) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  gap: 12px;
  padding:15px 20px;
  margin: 0 15px;
}
.ticker_panel img {
  width: 48px;
  height: 48px;
}
.ticker_content h3 {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 600;
}
.ticker_content h4 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  gap: 20px;
}

.ticker_settings .slick-prev{
  left: -60px;
}
.ticker_settings .slick-next{
  right: -60px;
  transform: rotate(180deg);
}
.ticker_settings .slick-prev:before, 
.ticker_settings .slick-next:before {
  color: #d4a0ff !important;
  font-size: 0;
  background-image:url("./assets/images/left_arw.png") ;
  width: 36px;
  height: 36px;
  display: block;
}

.ticker_settings .slick-next:hover:before {
  transform: rotate(180deg);
  background-image:url("./assets/images/right_arw.png") ;
}
.ticker_settings .slick-prev:hover:before{
  transform: rotate(180deg);
  background-image:url("./assets/images/right_arw.png") ;
}
.auth_form span.withdrawal_type {
  top: 18px;
  font-size: 20px;
  color: #d4a0ff;
}
.staking_calc.trade_buy {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}
.staking_calc.trade_buy .image_dropdown_wrapper .dropdown-menu {
  height: 200px;
}
.staking_calc.trade_buy .form-group {
  margin-top: 0;
  margin-bottom: 15px;
}
.staking_calc.trade_buy label {
  margin-bottom: 3px;
}
.rc-slider-dot {
  bottom: -5px !important;
  width: 14px !important;
  height: 14px !important;
}
.rc-slider {
  padding-left: 30px;
  padding-right: 30px;
}
.rcslider_custom {
  padding: 0 18px 0 18px;
}
.staking_method_btngrp button.trade_btn {
  height: auto;
  padding: 8px;
  border: 0;
  background: #161616;
}
button.trade_buy.trade_btn {
  color: #d4a0ff;
}
button.trade_sell.trade_btn {
  color: #d53d3d;
}
button.trade_buy.trade_btn.active {
  background: #d4a0ff;
  border: 1px solid #d4a0ff;
  color: #030303;
}
button.trade_sell.trade_btn.active {
  background: #d53d3d;
  border: 1px solid #d53d3d;
  color: #fff;
}
.staking_calc.trade_buy .staking_method_btngrp {
  margin-bottom: 15px;
}
.staking_calc.trade_buy button.primary_btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 18px;
  background: #d4a0ff;
  border: 1px solid #d4a0ff;
  color: #fff !important;
}
button.primary_btn.primary_sell_btn {
  border: 1px solid #d53d3d !important;
  background: #d53d3d !important;
}
.dash_box.trade_top_info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}
.trade_top_info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trade_top_info > div label {
  font-weight: 500;
}
.trade_top_info > div label:nth-child(2) {
  color: #d4a0ff;
}
.gap-10 {
  gap: 0 10px;
}


.crypto_box{
  background-color: rgb(0 205 249 / 10%);
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #d4a0ff;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
}
.crypto_box p{
  margin-bottom: 0;
  font-weight: 500;
  color: #000000;
}
.crypto_box span{
  font-size: 15px;
}
.copy_trade_row .slick-prev:before,.copy_trade_row .slick-next:before{
  background-image: url("./assets/images/nav_arrow.png");
  background-repeat: no-repeat;
  width: 10px;
  height: 17px;
  font-size: 0;
  display: block;
}
.copy_trade_row .slick-next:before{
  transform: rotate(180deg);
}
.coins_box > div{
  margin:5px 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.coins_box >div p{
  margin-bottom: 0;
  text-transform: uppercase;
}
.coins_box >div p.text-primary {
  color: #d4a0ff !important;
}
.coins_box .accordion-item{
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background: rgb(0 205 249 / 10%);
  border: 1px solid #d4a0ff;
}
.coins_box .accordion-item .accordion-button{
  border-radius: 10px !important;
  background: rgb(0 205 249 / 10%);
  color: #3e3e3e;
  box-shadow: none !important;
}
.accordion-button::after {
  filter: contrast(0) brightness(0) !important;
}
.menu-list div{
  padding: 12px 0;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
}
.menu-list div:hover{
  background: #d4a0ff;
  color: #ffffff;
}
.menu-list div img{
  margin-right: 7px;
}
.navbar_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.toggletheme_btn {
  border: none;
  background: transparent;
  color: #000;
}
.react-tel-input .form-control {
  height: 45px !important;
  border-radius: 30px !important;
  background: #ecfcff !important;
  border-color: rgb(0 205 249 / 30%) !important; 
  box-shadow: none !important;
  color: #626262;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.modal-header .btn-close {
  box-shadow: none !important;
}
.input-group span.primary_asset_inp {
  border: 1px solid;
}
.custom_pagination {
  justify-content: center;
  margin-top: 30px;
}
.custom_pagination .active>.page-link, 
.custom_pagination .page-link.active {
  background-color: #d4a0ff;
  border-color: #d4a0ff;
  color: #fff;
}
.custom_pagination .page-link {
  color: #090808;
  box-shadow: none !important;
}
.blog_title {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.blog_content {
  height: 150px;
  display: block !important;
  margin-bottom: 0 !important;
  overflow-x: auto;
  padding-right: 8px;
}

.stakingimg_box{
  position: relative;
  z-index: 1;
}

.stakingimg_box::before{
  content: "";
  position: absolute;
  background: url("./assets/images/stakebg.png") no-repeat center;
  height: 1177px;
  width: 150%;
  background-size: contain;
  z-index: -1;
  left: -180px;
  bottom: -50%;
}



.h2tag{
  font-weight: 600;
font-size: 40px;
color: #fff;
margin-bottom: 30px;
}
.h2tag span{
  color: #d4a0ff;
}
.ledger{
  background: #030303;
}
.ledger .inbox{
  border: 1px solid #1e1e1e;
 border-left: 0;
 border-right:0;
 padding: 35px 0;
}
.ledger .box{
  background: #101010;
  border-radius: 20px;
  padding: 15px;
  padding-bottom: 20px;
  transition: .5s;
  cursor: default;
}
.ledger .box:hover{
  transition: .5s;
  background: #d4a0ff;
}
.ledger .box h5{
  font-weight: 600;
font-size: 30px;
color: #fff;
}
.ledger .box p{
  font-weight: 400;
font-size: 20px;
line-height: 140%;
color: #bcbcbc;
}
.ledger .box>div:nth-child(1){
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #474747;
}
.ledger .box .arw{
  background-color: #090909;
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: .5s;
}
.ledger .box .img_box img{
  width: 100%;
}

.ledger .box:hover .arw{
  transition: .5s;
  background-color: #BE73FC;
}
.ledger .box:hover h5{
  color: #030303;
}
.ledger .box:hover p{
  color: #030303;
}


.ledger .blw {
  margin-top: 30px;
  background: url("./assets/images/ledger_bg.png") no-repeat center;
  position: relative;
  z-index: 1;
}
/* .ledger .blw::before {
  content: "";
  position: absolute;
  background: url("./assets/images/ledger_line.png") no-repeat center;
  width: 400px;
  height: 240px;
  background-size: contain;
  z-index: -1;
  right: 13%;
  top: 33%;
} */
.ledger .blw h2{
  font-weight: 600;
  font-size: 60px;
  text-transform: uppercase;
  color: #fff;
}
.ledger .blw h5{
  font-weight: 600;
font-size: 30px;
line-height: 93%;
text-align: right;
color: #d4a0ff;
margin-bottom: 0;
}
.ledger .blw p{
  margin-bottom: 0;
  text-align: right;
  color: #bcbcbc;
}

.modal-body .form-control{
  background: #030303;
  border-color: #1e1e1e;
  box-shadow: none !important;
  border-radius: 7px;
  color: #ffffff;
}
.staking_method_btngrp button {
  min-width: 150px;
  width: calc(50% - 10px);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* Dark Theme Css */
[data-theme="dark"] .ticker_panel {
  background: url("./assets/images/sliderbg.png") no-repeat center !important;
  background-size: cover !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;  

}
[data-theme="dark"] .navbar-toggler{
  filter: unset;
}
[data-theme="dark"] .main_navbar .offcanvas{
  background-color: #0b0e11;
}
[data-theme="dark"] .main_navbar .offcanvas-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .toggletheme_btn {
  color: #fff;
}
[data-theme="dark"] .navbar-sticky--moved-up {
  background: #000;
}
[data-theme="dark"] body {
  background: #000;
  color: #fff;
}
[data-theme="dark"] .page_header h1 {
  color: #fff;
}
[data-theme="dark"] .page_header p,
[data-theme="dark"] .primary_warning_alert p {
  color: #d5d5d5;
}
[data-theme="dark"] .footer {
  border: 0;
  background: #060606;
}
[data-theme="dark"] body::after { 
  background: url("../src/assets/images/footer_bg_dark.png") no-repeat;
  background-size: cover;
  height: 294px;
}
[data-theme="dark"] .footer_panel_top,
[data-theme="dark"] .dash_box_right_top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
[data-theme="dark"] .footer_panel_top h3,
[data-theme="dark"] .footer_panel_bottom p a {
  color: #fff;
}
[data-theme="dark"] .footer_quick_links li a,
[data-theme="dark"] .footer_panel_bottom p,
[data-theme="dark"] .footer_menu_links li a,
[data-theme="dark"] .main_para,
[data-theme="dark"] p.footer_abt_content,
[data-theme="dark"] .staking_calc label,
[data-theme="dark"] .staking_result_div p,
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu .dropdown-item,
[data-theme="dark"] .bal,
[data-theme="dark"] .main_navbar a.nav-link,
[data-theme="dark"] .nav_after_login li a.nav-link,
[data-theme="dark"] .wallet_table td,
[data-theme="dark"] .table_asset span,
[data-theme="dark"] .deposit_notes_list,
[data-theme="dark"] .assets_form label,
[data-theme="dark"] .primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell,
[data-theme="dark"] .primary_datatable .rdt_Pagination,
[data-theme="dark"] .nav_after_login li a.dropdown-item,
[data-theme="dark"] .user_info_content h5 span,
[data-theme="dark"] .accordion-item,
[data-theme="dark"] .withdrawal_type {
  color: #fff !important;
}
[data-theme="dark"] .nav_after_login .dropdown svg path,
[data-theme="dark"] .nav_after_login li a.nav-link svg path {
  fill: #c7c7c7;
}
[data-theme="dark"] .faq_accordian .accordion-item button,
[data-theme="dark"] .main_title,
[data-theme="dark"] .staking_result_div h3,
[data-theme="dark"] .inner_title,
[data-theme="dark"] .wallet_table th,
[data-theme="dark"] .primary_warning_alert h3,
[data-theme="dark"] .dash_box_right h4 {
  color: #fff;
}
[data-theme="dark"] .main_para b,
[data-theme="dark"] .countdown_single p {
  color: #e6e6e6;
}
[data-theme="dark"] .countdown_single {
  border: 1px solid rgba(67, 67, 67, 0.20);
  background: rgb(38 21 2 / 50%);
}
[data-theme="dark"] .countdown_single:nth-child(2) {
  background: rgb(10 3 31 / 50%);
}
[data-theme="dark"] .countdown_single:nth-child(3) {
  background: rgb(0 23 28 / 50%);
}
[data-theme="dark"] .countdown_single:nth-child(1) svg path:nth-child(1) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(3),
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(4) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(2),
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(3)  {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(5),
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(6) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(1),
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(2) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(3) svg path:nth-child(5) {
  fill: #fff;
  stroke: #fff;
}
[data-theme="dark"] .staking_works{
  background: #030303;
}
[data-theme="dark"] .staking_works:hover{
  background: url("./assets/images/stakebg_box.png") no-repeat center;
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
[data-theme="dark"] .staking_works h2 {
  color: #fff;
}
[data-theme="dark"] .staking_calc {
  border: 0;
  background: linear-gradient(154deg, #c682ff 0%, #ae73df 100%);
}
[data-theme="dark"] .form-text {
  color: #c0c0c0;
}
[data-theme="dark"] .staking_result_div {
  background: rgba(0, 0, 0, 0.15);
}
[data-theme="dark"] .staking_calc input {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .image_dropdown {
  background: rgb(0 0 0 / 15%) url("../src/assets/images/select_dropdown_dark.png") no-repeat
    center right 15px !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .dash_box .image_dropdown_wrapper .dropdown-menu {
  background: #100f10  !important;
}
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu {
  background: #a36bd1 !important;
}
[data-theme="dark"] .innerpages_wrapper::before {
  background: #000;
  background-size: cover;
}
[data-theme="dark"] .auth_wrapper .dash_box,
[data-theme="dark"] .dash_wrapper {
  border: 1px solid rgba(67, 67, 67, 0.05);
  background: #030303;
}
[data-theme="dark"] .dash_box  {
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: #030303;
}
[data-theme="dark"] .auth_form input.form-control,
[data-theme="dark"] .primary_asset_inp,
[data-theme="dark"] .react-tel-input .form-control {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .country-list {
  background-color: #131716 !important; 
}
[data-theme="dark"] .react-tel-input .country-list .search {
  background-color: #242827 !important;
}
[data-theme="dark"] .react-tel-input .country-list .search-box {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .country-list .country.highlight,
[data-theme="dark"] .react-tel-input .country-list .country:hover {
  background-color: #090808 !important;
}
[data-theme="dark"] .main_navbar a.nav-link.active, 
[data-theme="dark"] .main_navbar a.nav-link:hover {
  color: #d4a0ff  !important;
}
[data-theme="dark"] .wallet_table tbody tr:nth-child(odd) {
  background: rgb(230 255 248 / 4%);
}
[data-theme="dark"] .search_grp input {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid rgba(67, 67, 67, 0.75) !important;
  color: #fff !important;
}
[data-theme="dark"] .search_grp input::placeholder,
[data-theme="dark"] .primary_asset_inp::placeholder,
[data-theme="dark"] .staking_calc input::placeholder,
[data-theme="dark"] .react-tel-input .form-control::placeholder {
  color: #898989;
}
[data-theme="dark"] .search_icon,
[data-theme="dark"] .asset_copy_icon {
  filter: invert(1);
}
[data-theme="dark"] .primary_warning_alert {
  background: #0f1111;
  border-color: #0f1211;
}
[data-theme="dark"] .modal-content {
  background: #101010;
}
[data-theme="dark"] .modal-header {
  border-color: #2c2c2c; 
}
[data-theme="dark"] .modal-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .primary_datatable .rdt_Table, 
[data-theme="dark"] .primary_datatable .rdt_Table > div,
[data-theme="dark"] .user_info_content h3,
[data-theme="dark"] .primary_modal .modal-header h1 {
  color: #fff;
}
[data-theme="dark"] .staking_calc.trade_buy {
  box-shadow: none;
  background: transparent;
  border: none;
}
[data-theme="dark"] .staking_calc .input-group .primary_asset_inp { 
  background: #0f1010 !important;
}
[data-theme="dark"] .primary_asset_select {  
  border-color: #1e1e1e !important;
  background: rgb(0 0 0 / 15%) url("../src/assets/images/select_dropdown_dark.png") no-repeat;
  background-position: right 0.75rem center;
  color: #fff;
}
[data-theme="dark"] .primary_asset_select option {
  background-color: #0d0e0e !important; 
}
[data-theme="dark"] .asset_dashbox_flex_line::after { 
  background: #363737;
}
[data-theme="dark"] .primary_datatable .rdt_TableHeadRow .rdt_TableCol { 
  color: #fff;
}
[data-theme="dark"] .primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(0 0 0 / 30%);
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button {
  fill: #c7c7c7 !important;
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button:disabled {
  fill: #818181 !important;
}
[data-theme="dark"] .nav_after_login ul.dropdown-menu {
  border: 1px solid rgb(54 54 54);
  background: rgb(15 17 16);
}
[data-theme="dark"] .nav_after_login li a.dropdown-item:focus, 
[data-theme="dark"] .nav_after_login li a.dropdown-item:hover {
  color: #fff !important;
}
[data-theme="dark"] .ticket_msg_card { 
  background: #0f1211;
  box-shadow: 0px 0px 15px 0px rgb(23 23 23 / 35%);
}
[data-theme="dark"] .ticket_msg_card_header p {
  color: #afafaf;
}
[data-theme="dark"] .ticker_content h3 {
  color: #fff;
}
[data-theme="dark"] .ticker_content h4 {
  color: #bcbcbc;
}
[data-theme="dark"] .crypto_box p {
  color: #ffffff;
}
[data-theme="dark"] .crypto_box {
  background-color: rgb(0 205 249 / .2%);
  border: 1px solid #373b3c;
}
[data-theme="dark"] .coins_box >div {
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.30);
}

[data-theme="dark"] .coins_box .accordion-item{
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  border: 1px solid #161616;
  background-color: rgb(0 205 249 / .2%);
}
[data-theme="dark"] .coins_box .accordion-item .accordion-button{
  border-radius: 10px !important;
  background: #0b0c0c;
  color: #ffffff;
  box-shadow: none !important;
}
[data-theme="dark"] .coins_box .accordion-button::after {
  filter: contrast(0) brightness(0) invert(1) !important;
}
[data-theme="dark"] .accordion-button::after {
  filter: unset !important;
}
[data-theme="dark"] .custom_pagination .active>.page-link, 
[data-theme="dark"] .custom_pagination .page-link.active {
  background-color: #d4a0ff;
  border-color: #d4a0ff;
  color: #fff;
}
[data-theme="dark"] .custom_pagination .page-link {
  color: #c7c7c7;
  box-shadow: none !important;
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.30);
}
.image_dropdown.border-0 {
  border: 0 !important;
}
.form-control.border-0.bdro{
  border: 0 !important;
}
.footer_participant h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 93%;
  color: #d4a0ff;
  margin-bottom: 0;
}
.footer_participant p {
  margin-bottom: 0;
  color: #bcbcbc;
}




/* Media Queries */
@media screen and (max-width: 1399px) {
  .page_header h1{
    font-size: 75px;
  }
  .page_header h1 span{
    padding-bottom: 7px;
  }
  .copy_trade_row .slick-prev:before{
     left: 12px;
     position: relative;
  }
  .dash_wrapper {
    border-radius: 30px;
    padding: 20px;
  }
  .dash_box.dash_box_right {
    padding: 36px 20px;
  }
  .dash_box_right_large_single h3 {
    font-size: 18px;
  }
  .primary_btn.primary_btn_transform {
    font-size: 14px;
    padding: 8px 12px;
  }
  .dash_box_right_top h4 {
    font-size: 16px;
  }
  .dash_box_right_bottom h2 {
    font-size: 28px;
  }
  .countdown_section {
    margin-top: -120px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 32%;
  }
}
@media screen and (max-width: 1199px) {
  .page_header .page_header_inner::after{
    background-size: 50%;
    top: 50px;
  }
  .page_header .page_header_inner::before{
    background-size: 27%;
  }
  .page_header h1 {
    font-size: 65px;
}
  .ledger .blw h2{
    font-size: 50px;
  }
  .dash_box.dash_box_left {
    height: 100%;
  }
  .dashbox_left_inner_row [class*="col-"] {
    margin-bottom: 24px;
  }
  .page_header::after {
    display: none;
  }
  .countdown_section {
    margin-top: 0;
    padding-top: 70px;
  }
  .countdown_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .features_panel p.main_para {
    max-width: 100%;
  }
  ul.navbar_left {
    margin-left: 20px;
    display: none;
  }
  .navbar-toggler {
    filter: invert(1);
    box-shadow: none !important;
  }
  .notify_active {
    right: -28px;
    top: 6px;
    left: unset;
  }
  .bal{
    color: #1e1e1e !important;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .support_ticket_accordian .accordion-header .accordion-button {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .support_ticket_accordian .accordion-button::after {
    top: 30px;
  }
  .tradingview-widget-container {
    height: 500px;
  }
}
@media screen and (max-width: 991px) {
  .footer_social_links li a svg {
    width: 15px;
    height: 19px;
}
  .footer_social_links li a {
    width: 40px;
    height: 40px;
  }
  .ledger .blw h2 {
    font-size: 40px;
}
  .ledger .box .arw {
    width: 43px;
    height: 43px;
  }
  .ledger .box .arw svg{
    width: 15px;
  }
  .ledger .box {
    margin-bottom: 15px;
  }
  .ledger .box h5{
    font-size: 24px;
  }
  .page_header .blw_ctn{
    width: 100%;
  }
  p.footer_abt_content {
    max-width: 90%;
  }
  .dash_box_right_large_single {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .dash_box_right_large_single h5 {
    margin-bottom: 10px;
  }
  .dash_box_right_large_single::after {
    height: 100%;
  }
  .navbar_left {
    display: none;
  }
  .auth_btn_grp {
    flex-direction: column;
  }
  .auth_btn_grp .primary_btn {
    display: block;
    text-align: center;
  }
  .page_header_inner {
    text-align: center;
  }
  .footer {
    padding: 30px 0 80px;
  }
  .toolbar_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    background: #d4a0ff;
    width: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s;
    z-index: 999;
  }
  .toolbar_bottom ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .toolbar_bottom ul li svg {
    fill: #fff;
  }
  .toolbar_bottom ul li {
    text-align: center;
  }
  .toolbar_bottom ul li span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
  .toolbar_bottom .navbar-toggler {
    filter: unset;
  }
  .toolbar_bottom ul li a,
  .toolbar_bottom ul li button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 12px;
  }
  .toolbar_bottom ul li a.active {
    background: #075e3f;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 40%;
    margin-right: 30px;
  }
  .asset_dashbox_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .asset_dashbox_flex_left,
  .asset_dashbox_flex_right {
    width: 100%;
  }
  .asset_dashbox_flex_line::after {
    display: none;
  }
  .staking_calc [class*="col-"] {
    margin-bottom: 15px;
  }
  .auth_wrapper .dash_box {
    padding: 30px;
  }
  .dash_wrapper.dash_wrapper_flex {
    flex-direction: column;
  }
  .dash_wrapper_flex_left, 
  .dash_wrapper_flex_right {
    width: 100%;
  }
  .dash_wrapper_flex::after {
    display: none;
  }
  .staking_top_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }  
  .dash_box.trade_top_info {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 5px;
  }
  .coins_box {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .staking_calc {
    border-radius: 15px;
    padding: 20px;
  }
  .ledger .blw h2 {
    font-size: 30px;
}
  .ledger .box p{
    font-size: 18px;
  }
  .ledger .box h5 {
    font-size: 20px;
}
  .h2tag{
    font-size: 30px;
  }
  .page_header .page_header_inner::before{
    left: -50px;
    top: -40px;
  }
  .dash_box_right_large {
    flex-direction: column;
    align-items: flex-start;
  }
  .dash_box_right_large_single {
    width: 100%;
    flex-direction: row;
  }
  .dash_box_right_large_single::after {
    width: 100%;
    height: 1px;
    right: unset;
    top: unset;
    bottom: -15px;
  }
  .progress_note {
    flex-direction: column;
    gap: 10px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 92%;
  }
  .progress_note label {
    text-align: center;
  }
  .footer_panel_bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer_panel_bottom p {
    line-height: 24px;
  }
  .page_header::before {
    background-size: contain;
    height: 384px;
  }
  .page_header h1 {
    font-size: 46px;
  }
  .page_header h1 img{
    max-width: 13%;
  }
  .page_header h1 span{
    border-radius: 6px;
    line-height: 33px;
  }
  .countdown_single h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .features_panel {
    padding: 30px 50px;
  }
  .main_title {
    font-size: 36px;
    line-height: 42px;
  }
  .features_panel h3 {
    margin: 10px 0;
  }
  .auth_wrapper {
    padding: 50px 0;
  }
  .faq_accordian .accordion-item button {
    font-size: 16px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 180px;
    margin-right: 10px;
  }
  .level_head > div:nth-child(3),
  .level_body_row > div:nth-child(3),
  .level_head > div:nth-child(2),
  .level_body_row > div:nth-child(2) {
    width: 150px;
    margin-right: 10px;
  }
  .deposit_asset_details > div {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit_asset_details > div label {
    width: 100%;
    line-height: 24px;
  }
  .notification_panel_row {
    flex-direction: column;
  }
  .notification_panel_row p.notify_date {
    margin-left: 15px;
  }
  .level_ribbon,
  .level_ribbon.active {
    background-size: contain;
  }
  .notify_active {
    right: -25px;
    left: unset;
  }
  .staking_method_btngrp {
    flex-wrap: wrap;
  }
  .staking_method_btngrp button {
    width: 48%;
  }
  .staking_result_div p {
    text-align: center;
  }
  .staking_result_div {
    flex-direction: column;
    padding: 30px;
    text-align: center;
  }
  .page_header_inner {
    padding: 30px;
  }
  .support_ticket_accordian .accordion-header .accordion-button > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .ticket_msg_card_user,
  .ticket_msg_card_admin {
    width: 100%;
  }
  .ticket_msg_card {
    padding: 15px;
  }
  .search_grp,
  .search_grp input {
    width: 100%;
  }
  .wallet_page_right_flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 575px) {
  .page_header h1 span {
    border-radius: 6px;
    line-height: 20px;
}
  .uploaded_files_row > div span{
    max-width: 60%;
    overflow: hidden;
  }
  .uploaded_files_row{
    align-items: flex-start;
  }
  .inner_subtitle_wrap_flex {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .dash_box_right_large_single h3 {
    font-size: 16px;
  }
  .dash_box_right h5 {
    font-size: 14px;
  }
  .level_body_row > div,
  .level_head > div {
    font-size: 14px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 85%;
  }
  .asset_dashbox_flex {
    padding: 15px;
  }
  .assets_form label,
  label.max_label {
    font-size: 14px;
  }
  .deposit_asset_details > div label {
    font-size: 15px;
  }
  .asset_transfer_flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .asset_transfer_flex img {
    transform: rotate(90deg);
  }
  .user_info {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking_method_btngrp button {
    width: 100%;
    padding: 12px 15px;
  }
  .page_header .sub_head {
    font-size: 18px;
  }
  .page_header h1 {
    font-size: 32px;
  }
  .auth_wrapper .dash_box {
    padding: 30px 15px;
  }
  .ticker_panel {
    width: 85%;
    margin: 0 auto;
    gap: 5px;
  }
  .ticker_settings .slick-prev {
    left: 0;
    z-index: 1;
  }
  .ticker_settings .slick-next {
    right: 0;
  }
  .ticker_panel img {
    width: 40px;
    height: 40px;
  }
  .ticker_content h3 {
    font-size: 20px;
  }
  .ticker_content h4 {
    font-size: 16px;
    gap: 10px;
  }
}
.verticalScroll > div:first-child{
  padding-right: 20px !important;
}
.verticalScroll > div:last-child{
  margin-right: 0px;
  opacity: 1 !important;
  background: rgba(0,0,0,.4) !important;
  width: 5px !important;
}
.verticalScroll > div:last-child > div{
  background: #d4a0ff !important;
}













.orderbook_wrap {
  box-sizing: border-box; 
  min-width: 0px;
  width: 100%; 
  grid-area: orderbook / orderbook / orderbook / orderbook;
  position: relative;
  overflow: hidden;
  /* background: var(--dark-01); */
  /* padding-bottom: 8px; */
}

.orderbook_wrap_inner {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  /* height: 100%; */
  /* background: var(--dark-01); */
  border-radius: 0 0 10px 10px;
}

.orderbook_header {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background: var(--spot_headers);
  border-bottom: 1px solid var(--grey-04);
}

.orderbook_header .orderbook_header_tips {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.orderbook_header_tips button {
  border: none;
  background: transparent;
  padding: 0;
}

.orderbook_header_tips button+button {
  margin-left: 12px;
}

.orderbook_tickSize {
  position: initial;
  right: 0px;
  flex: 1.2 1 0%;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.orderbook_tickSize select {
  border: none;
  /* background: transparent url("/assets/images/caret_arrow_icon.png") no-repeat right 0.75rem center; */
  color: red;
  /* font-size: var(--font-6); */
  box-shadow: none !important;
}

.orderbook_tbheader {
  display: flex;
  flex-direction: column;
  margin: 6px 13px 6px 0px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0;
}

.orderlist_container {
  display: flex;
  flex: 1 1 0%;
  padding: 8px 0;
  flex-direction: column;
}

.orderbook_list {
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1 1 0%; */
  padding: 10px 0;
}

.orderbook_ticker {
  display: flex;
  padding: 1px 12px;
  height: 33px;
  -webkit-box-align: center;
  align-items: center;
  background: var(--bs-border-color-translucent);
  justify-content: space-between;
  font-size: 14px;
  color: var(--white);
}

.orderbook_tbheader .content {
  display: flex;
  height: 20px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  color: #707a8a;
}

.orderbook_tbody_row_bar_sell {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_tbody_row_bar_buy {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_list_container .orderbook_tbody_row_bar_sell {
  background: rgba(254, 83, 91, 0.2);
}


.orderbook_list_container .orderbook_tbody_row_bar_buy {
  background: rgba(56, 173, 123, 0.2);
}



.orderbook_tbheader .item {
  display: flex;
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 500;
}

.orderbook_progress .row_content {
  padding-left: 10px !important;
  padding-right: 8px !important;
}

.orderbook_progress {
  height: 22px;
  width: 100%;
  box-sizing: border-box;
}

.orderbook_list .row_content {
  display: flex;
  box-sizing: border-box;
  z-index: 2;
  width: 98%;
  height: 100%;
  line-height: 22px;
  cursor: pointer;
}

.orderbook_list .text {
  font-size: 12px;
  margin-left: 0px;
  flex: 1 1 0%;
  text-align: right;
  color: var(--white);
  cursor: pointer;
  font-weight: 300;
}

.orderbook_list .progress_container {
  height: 22px;
}

.orderlist_container.show_sell {
  padding-bottom: 0;
}

.orderlist_container.show_sell .orderbook_bid {
  display: none;
}

.orderbook_ask .orderbook_list_container {
  display: flex;
  flex-direction: column-reverse;
}

.orderlist_container.show_sell .orderbook_list_container {
  height: 767px;
}

.orderlist_container.show_buy {
  padding-bottom: 0;
}

.orderlist_container.show_buy .orderbook_ask {
  display: none;
}

.orderlist_container.show_buy .orderbook_list_container {
  height: 767px;
}

.progress_container {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1 1 0%;
  height: 20px;
  -webkit-box-align: center;
  align-items: center;
  animation: 0.3s ease-out 0s 1 normal none running none;
  overflow: hidden;
}

.orderbook_list .ask_bar {
  background-color: var(--red);
}

/* .orderbook_list .progress_bar {
  height: 22px;
}
.orderbook_progress .progress_bar {
  position: absolute;
  right: 0px;
  z-index: 1;
  height: 20px;
  opacity: 0.1;
  width: 100%;
  left: 100%;
} */
.orderbook_list .ask_light,
.orderbook_list .bid_light {
  font-size: 12px;
  flex: 1 1 0%;
  text-align: left;
}

.orderbook_list .ask_light {
  color: var(--red);
}

.orderbook_list .bid_light {
  color: var(--green);
}

.orderbook_ticker .yellow {
  color: var(--color-01);
}

.orderbook_ticker .status_sell {
  color: var(--red);
}

.orderbook_ticker .status_green,
.orderbook_ticker .status_buy {
  color: var(--green);
}

.orderbook_ticker .status_primary {
  color: #1e2329;
}

.orderbook_ticker .contractPrice {
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.orderbook_ticker .markPrice {
  flex: 1 1 0%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  color: #707a8a;
}

.orderbook_ticker .more {
  color: #707a8a;
  font-size: 12px;
  text-decoration: none;
}

.orderbook_ticker .arrow_icon {
  font-size: 16px;
}

.orderbook_list .bid_bar {
  background-color: #0ecb81;
}

.orderbook_list_container {
  position: relative;
  height: 367px;
  height: 167px;
  overflow: hidden;
  will-change: transform;
  direction: ltr;
}

.chart_wrapper {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  grid-area: chart / chart / chart / chart;
  position: relative;
}
